import { useEffect, useState } from "react";
import { useAtom } from "jotai";

import * as state from "../../../state";

function Leaderboard() {
  const [username] = useAtom(state.username);

  const [competitor] = useAtom(state.competitor);
  const [transactions] = useAtom(state.transactions);
  const [pnls] = useAtom(state.pnls);

  const [ranking, setRanking] = useState(null);

  useEffect(() => {
    setRanking(null);
    if (!competitor || !transactions || !pnls || !(competitor.id.substring(28) in transactions) || !(competitor.id.substring(28) in pnls)) {
      setRanking(null);
      return;
    }

    let last_price = 50;
    if (competitor.settled !== null) {
      last_price = competitor.settled;
    }
    else if (competitor.id.substring(28) in transactions && !!transactions[competitor.id.substring(28)].filter(t => !!t.b && !!t.s).length) {
      last_price = transactions[competitor.id.substring(28)].filter(t => !!t.b && !!t.s)[0].p;
    }

    setRanking(
      pnls[competitor.id.substring(28)].map(pnl => {
        return {
          "username": pnl.u,
          "position": pnl.p,
          "pnl": pnl.b + pnl.p * last_price,
        }
      }).sort((a, b) => b.pnl - a.pnl)
    );
  }, [competitor, transactions, pnls]);

  return (
    <div className="rounded-lg bg-darkgrey py-4 px-6 pr-3">
      <p className="text-body-lg-m pb-3 text-left">{!!competitor && competitor.name} PnL Leaderboard:</p>
      <div className="max-h-[300px] overflow-auto pr-3">
        <table className="overf-y-auto w-full table-auto">
          <thead className="text-body-xs border-b border-b-table-border text-greytext">
            <tr>
              <th className="w-20 py-[5px] px-[10px] text-left">Rank</th>
              <th className="py-[5px] px-[10px] text-left">Name</th>
              <th className="py-[5px] px-[10px] text-left">PnL</th>
              <th className="py-[5px] px-[10px] text-right">Position</th>
            </tr>
          </thead>
          <tbody className="text-body-s overf-y-auto h-full max-h-[500px] w-full">
            {!!ranking && ranking.map((r, i) => (
              <tr key={i} className={`cursor-pointer overflow-auto rounded-lg ${r.username === username ? "text-lightblue" : ""} hover:bg-lightgrey `}>
                <td className="py-[5px] px-[10px]">{i+1}</td>
                <td className="py-[5px] px-[10px]">{r.username}</td>
                <td className="py-[5px] px-[10px]">{r.pnl}</td>
                <td className={`py-[5px] px-[10px] text-right ${r.position >= 0 ? "text-lightgreen" : "text-lightred"}`}>{r.position}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Leaderboard;

