import { useState } from "react";
import { useAtom } from "jotai";

import * as state from "../../../../state";
import { WEBSOCKET_ACTION } from "../../../../constants";

function Trader({ websocket }) {
  const [token] = useAtom(state.token);
  const [enrolled, setEnrolled] = useAtom(state.enrolled);

  const [tournaments, setTournaments] = useAtom(state.tournaments);
  const [tournament, setTournament] = useAtom(state.tournament);
  const [competitor, setCompetitor] = useAtom(state.competitor);
  const [positions, setPositions] = useAtom(state.positions);
  const [outstandings, setOutstandings] = useAtom(state.outstandings);
  const [sequences, setSequences] = useAtom(state.sequences);
  const [orderbooks, setOrderbooks] = useAtom(state.orderbooks);
  const [transactions, setTransactions] = useAtom(state.transactions);
  const [pnls, setPnls] = useAtom(state.pnls);
  const [midmarket, setMidmarket] = useAtom(state.midmarket);

  const [modalShown, setModalShown] = useState(false);

  const handleAdd = (tournament_id) => {
    fetch(`${import.meta.env.VITE_API_URL}/tournaments`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'X-API-Key': token
      },
      body: JSON.stringify({
        "id": tournament_id
      })
    })
      .then(r => r.json().then(json => ({ status: r.status, ok: r.ok, body: json })))
      .then(response => {
        if (!response.ok) {
          let err = new Error(`HTTP status code: ${response.status}`);
          err.status = response.status
          throw err
        }
        return response.body
      })
      .then(json => {
        setTournaments(json.tournaments);
        setEnrolled(json.enrolled);
        const tournament = json.tournaments.find(t => t.id === tournament_id);
        setTournament(tournament);
        setCompetitor(null);
        setMidmarket(null);
        setModalShown(false);
      });
  }

  const handleRemove = (tournament_id) => {
    fetch(`${import.meta.env.VITE_API_URL}/tournaments/${tournament_id}`, {
      method: "DELETE",
      headers: {
        'Content-Type': 'application/json',
        'X-API-Key': token
      }
    })
      .then(r => r.json().then(json => ({ status: r.status, ok: r.ok, body: json })))
      .then(response => {
        if (!response.ok) {
          let err = new Error(`HTTP status code: ${response.status}`);
          err.status = response.status
          throw err
        }
        return response.body
      })
      .then(json => {
        setTournaments(json.tournaments);
        setEnrolled(json.enrolled);
        const registered = json.tournaments.filter(t => json.enrolled.includes(t.id));
        if (registered.length) {
          setTournament(registered[0]);
          setCompetitor(null);
          setMidmarket(null);
        }
        else {
          setTournament(null);
          setCompetitor(null);
          setMidmarket(null);
          websocket.send(JSON.stringify({
            "token": token,
            "action": WEBSOCKET_ACTION.UNSUBSCRIBE,
            "data": {}
          }));
        }
        setModalShown(false);
      });
  }

  const handleSelect = (tournament) => {
    setPositions(null);
    setOutstandings(null);
    setSequences(null);
    setOrderbooks(null);
    setTransactions(null);
    setPnls(null);
    setMidmarket(null);
    setTournament(tournament);
    setCompetitor(null);
  }

  return (
    <section className="mt-6">
      <div className="flex items-center justify-between sm:flex-col-reverse sm:gap-2">
        <p className="text-body-lg-m">My Tournaments:</p>
        {!!tournament && (!!tournament.market_open ? <p className="market-status open">Market Open</p> : <p className="market-status closed">Market Closed</p>)}
      </div>
      <div className="relative border-b border-b-[#3C4547] py-[18px] sm:py-2">
        <div className="flex gap-4 sm:flex-wrap sm:gap-1">
          {!!tournaments && tournaments.filter(t => enrolled.includes(t.id)).map((t, i) => (
            <button key={i} className={`tournament-button ${!!tournament && t.id === tournament.id && "active"}`} {...(!!tournament && t.id !== tournament.id && { onClick: () => handleSelect(t) })}>
              <img src={`${import.meta.env.VITE_CDN_URL}/tournaments/logos/${t.id}`} style={{height: "30px"}} className="sm:h-5 sm:w-5" />
              <span>{t.name}</span>
            </button>
          ))}
          <button onClick={() => setModalShown(!modalShown)} className="ml-auto flex h-[54px] w-[54px] items-center justify-center rounded-lg border border-transparent bg-darkgrey p-[18px] text-xl hover:border-lightgreen sm:h-5 sm:w-5">
            <span>+</span>
          </button>
          <div className={`absolute right-0 top-full z-20 -mt-2 ${modalShown ? "block" : "hidden"} w-[732px] rounded-lg border border-table-border bg-darkgrey px-6 pb-6 pt-5 md:w-full`}>
            <p className="text-body-lg-m py-4 px-6 pb-3 text-center">
              Add Tournaments
            </p>
            <button onClick={() => setModalShown(!modalShown)} className="group absolute right-3 top-3">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="12" r="12" fill="#313A3D" />
                <path
                  className="transition-colors group-hover:fill-lightgreen"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M16.7891 8.02444C17.0234 7.79013 17.0234 7.41023 16.7891 7.17591C16.5548 6.9416 16.1749 6.9416 15.9406 7.17591L11.9826 11.1339L8.02446 7.17579C7.79014 6.94147 7.41025 6.94147 7.17593 7.17579C6.94162 7.4101 6.94162 7.79 7.17593 8.02431L11.1342 11.9826L7.17593 15.9409C6.94162 16.1753 6.94162 16.5552 7.17593 16.7895C7.41024 17.0238 7.79014 17.0238 8.02446 16.7895L11.9825 12.8315L15.9406 16.7896C16.1749 17.0239 16.5548 17.0239 16.7891 16.7896C17.0234 16.5553 17.0234 16.1754 16.7891 15.9411L12.8308 11.9828L16.7891 8.02444Z"
                  fill="#A8ABAD"
                />
              </svg>
            </button>
            <div className="grid grid-cols-3 overflow-hidden rounded-lg border border-table-border">
              {!!tournaments && tournaments.map((t, i) => (
                <label key={i} className="add-tournament-button border-b border-r border-table-border">
                  <div className="flex items-center gap-2">
                    <div className="h-[30px] w-[30px] shrink-0 overflow-hidden rounded-full">
                      <img src={`${import.meta.env.VITE_CDN_URL}/tournaments/logos/${t.id}`} />
                    </div>
                    <div className="flex flex-col gap-1">
                      <p className="text-body-xs-medium sm:text-body-x uppercase">{t.name}</p>
                    </div>
                  </div>
                  <input type="checkbox" checked={enrolled.includes(t.id)} onChange={() => enrolled.includes(t.id) ? handleRemove(t.id) : handleAdd(t.id)} className="absolute right-2 bottom-2 hidden rounded-full checked:block" />
                </label>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Trader;
