import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import isFloat from 'validator/es/lib/isFloat';
import isInt from 'validator/es/lib/isInt';

import * as state from "../../../state";

function Settlement() {
  const [token] = useAtom(state.token);

  const [tournaments, setTournaments] = useAtom(state.tournaments);
  const [tournament, setTournament] = useAtom(state.tournament);
  const [competitor, setCompetitor] = useAtom(state.competitor);

  const [selected, setSelected] = useState(null);
  const [price, setPrice] = useState("0");
  const [submitted, setSubmitted] = useState(false);
  const [errorText, setErrorText] = useState("");

  const handleSubmit = (evt) => {
    setErrorText("");
    setSubmitted(true);
    evt.preventDefault();

    if (!isFloat(price, { min: 0, max: 100 }) || !isInt((price / 0.5).toString())) {
      setErrorText(`Invalid price ${price}`);
      setSubmitted(false);
      return;
    }

    fetch(`${import.meta.env.VITE_API_URL}/tournaments/${tournament.id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": token
      },
      body: JSON.stringify({
        "type": "settle",
        "competitor": selected,
        "price": parseFloat(price)
      })
    })
      .then(r => r.json().then(json => ({ status: r.status, ok: r.ok, body: json })))
      .then(response => {
        if (!response.ok) {
          let err = new Error(`HTTP status code: ${response.status}`);
          err.status = response.status
          throw err
        }
        return response.body
      })
      .then(json => {
        setTournaments(json.tournaments);
        const t = json.tournaments.find(t => t.id === tournament.id);
        setTournament(t);
        setCompetitor(t.competitors.find(c => c.id === selected));
        setSubmitted(false);
        setPrice(0);
      });
  }

  useEffect(() => {
    if (!competitor) return;

    setSelected(competitor.id);

  }, [competitor]);

  return (
    <div className="rounded-lg bg-darkgrey py-4 px-6">
      <p className="text-body-lg-m pb-3 text-left">Settlement</p>
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-3 gap-[2px]">
          <div className="col-span-2 grid">
            <label className="input-label" htmlFor="team">
              <p className="text-body-xs text-greytext">Team/Player</p>
              <select id="team" name="team" {...(!!selected && { value: selected })} onChange={e => setSelected(e.target.value)} className="input-small rounded-tr-none rounded-br-none">
                {!!tournament && tournament.competitors.filter(c => c.settled === null).map((c, i) => (<option key={i} value={c.id}>{c.name}</option> ))}
              </select>
            </label>
          </div>
          <div className="col-span-1 grid">
            <label className="input-label" htmlFor="price">
              <p className="text-body-xs text-greytext">Price</p>
              <input
                id="price"
                name="price"
                type="text"
                value={price}
                onChange={e => setPrice(e.target.value)}
              className="input-small rounded-tl-none rounded-bl-none"
              />
            </label>
          </div>
        </div>
        {!!errorText && <div className="grid grid-cols-2 gap-3 text-body-s-medium mt-6 text-lightred">{errorText}</div>}
        <button className="btn btn-small mt-5" type="submit">Confirm Settlement</button>
      </form>
    </div>
  );
}

export default Settlement;

