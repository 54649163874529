import { useEffect, useRef, useState } from "react";
import { useAtom } from "jotai";

import * as state from "../../../../state";
import { WEBSOCKET_ACTION } from "../../../../constants";

function Trader({ websocket }) {
  const [token] = useAtom(state.token);

  const [tournament] = useAtom(state.tournament);
  const [competitor] = useAtom(state.competitor);
  const [sequences] = useAtom(state.sequences);
  const [outstandings] = useAtom(state.outstandings);
  const [orderbooks] = useAtom(state.orderbooks);
  const [midmarket, setMidmarket] = useAtom(state.midmarket);

  const [canTrade, setCanTrade] = useState(false);

  const midmarketRef = useRef();

  const handleSubmit = (action, price) => {
    if (!tournament || !competitor || !sequences) return;

    websocket.send(JSON.stringify({
      "token": token,
      "action": WEBSOCKET_ACTION.ORDER,
      "data": {
        "tournament": tournament.id,
        "competitor": competitor.id,
        "action": action,
        "sequence": competitor.id.substring(28) in sequences ? sequences[competitor.id.substring(28)] : 1,
        "price": price,
        "quantity": 1,
      }
    }));
  }

  const handleCancel = (action, price, quantity) => {
    if (!tournament || !competitor || !sequences) return;

    const q = parseInt(quantity, 10);

    websocket.send(JSON.stringify({
      "token": token,
      "action": WEBSOCKET_ACTION.ORDER,
      "data": {
        "tournament": tournament.id,
        "competitor": competitor.id,
        "action": action,
        "sequence": competitor.id.substring(28) in sequences ? sequences[competitor.id.substring(28)] : 1,
        "price": price,
        "quantity": quantity,
      }
    }));
  }

  useEffect(() => {
    if (!midmarket) return;

    const {current} = midmarketRef
    if (current !== null){
      current.scrollIntoView({ behavior: "smooth",   block: "center" })
    }

  }, [midmarket]);

  useEffect(() => {
    if (!!midmarket) return;

    let bid, ask;
    if (!!orderbooks && !!competitor && competitor.id.substring(28) in orderbooks && "b" in orderbooks[competitor.id.substring(28)] && Object.keys(orderbooks[competitor.id.substring(28)].b).length){
      bid = Math.max(...Object.keys(orderbooks[competitor.id.substring(28)].b).map(k => parseFloat(k)));
    }
    if (!!orderbooks && !!competitor && competitor.id.substring(28) in orderbooks && "a" in orderbooks[competitor.id.substring(28)] && Object.keys(orderbooks[competitor.id.substring(28)].a).length){
      ask = Math.max(...Object.keys(orderbooks[competitor.id.substring(28)].a).map(k => parseFloat(k)));
    }

    if (!!bid && !!ask){
      setMidmarket(Math.floor((bid * 2 + ask * 2) / 2) / 2);
    }
    else if (!!bid){ 
      setMidmarket(bid);
    }
    else if (!!ask){
      setMidmarket(ask);
    }

  }, [orderbooks, competitor, midmarket]);

  return (
    <div className="rounded-lg bg-darkgrey py-4 px-6 pr-3">
      <div className="flex justify-between">
        <p className="text-body-lg-m pb-3 text-left">{!!competitor && competitor.name} Order Book</p>
        <button onClick={() => setCanTrade(!canTrade)} className="lock-button group flex h-6 w-6 items-center justify-center rounded-md border border-transparent bg-lightgrey p-[5px] hover:border-lightgreen">
          {canTrade ? (
            <img className="lock-icon group-[.active]:block" src="/img/lock-icon-unlocked.svg" alt="Trading Unlocked" />
          ) : (
            <img className="lock-icon group-[.active]:block" src="/img/lock-icon-locked.svg" alt="Trading Locked" />
          )}
        </button>
      </div>
      <div className="order-book-table max-h-[429px] overflow-auto pr-3">
        <table className="w-full table-fixed border-collapse overflow-y-auto">
          <thead className="text-body-xs text-greytext">
            <tr>
              <th className="border-r border-r-table-border py-[5px] px-2 text-center text-white">My Bid</th>
              <th className="py-[5px] px-2 text-center">Bid</th>
              <th className="border-x border-x-table-border py-[5px] px-2 text-center">Price</th>
              <th className="py-[5px] px-2 text-center">Ask</th>
              <th className="border-l border-l-table-border py-[5px] px-2 text-center text-white">My Ask</th>
            </tr>
          </thead>
          <tbody className="text-body-s overf-y-auto h-full max-h-[500px] w-full">
            {Array.from({length: 199}, (_, i) => (i + 1) / 2).reverse().map(price => (
              <tr key={price} {...(price === midmarket && { ref: midmarketRef })} className="overflow-auto rounded-lg border-y border-y-table-border">
                <td className="text-center leading-7">
                  {!!competitor && !!outstandings && competitor.id.substring(28) in outstandings && price in outstandings[competitor.id.substring(28)] && outstandings[competitor.id.substring(28)][price] > 0 ? (
                    <div {...(canTrade && { onClick: () => handleSubmit("bid", price) })} className="text-body-s relative flex h-full w-full items-center justify-center bg-lightgrey leading-7">
                      <button {...(canTrade && { onClick: (e) => { e.stopPropagation(); handleCancel("unbid", price, outstandings[competitor.id.substring(28)][price])}})} className="group absolute right-[2px] top-[2px]">
                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                          <path
                            className="group-hover:fill-lightgreen"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M7.06888 1.63834C7.26415 1.44307 7.26415 1.12649 7.06888 0.931228C6.87362 0.735966 6.55704 0.735966 6.36178 0.931228L4.19238 3.10063L2.02297 0.931222C1.82771 0.73596 1.51113 0.735961 1.31587 0.931222C1.1206 1.12648 1.1206 1.44307 1.31587 1.63833L3.48527 3.80773L1.31587 5.97713C1.1206 6.17239 1.1206 6.48897 1.31587 6.68424C1.51113 6.8795 1.82771 6.8795 2.02297 6.68424L4.19237 4.51484L6.36178 6.68424C6.55704 6.8795 6.87362 6.8795 7.06889 6.68424C7.26415 6.48898 7.26415 6.1724 7.06889 5.97713L4.89948 3.80774L7.06888 1.63834Z"
                            fill="#A8ABAD"
                          />
                        </svg>
                      </button>
                      <span>{outstandings[competitor.id.substring(28)][price]}</span>
                    </div>
                    ) : (
                      <div {...(canTrade && { onClick: () => handleSubmit("bid", price) })} className="text-body-s relative flex h-full w-full items-center justify-center bg-lightgrey leading-7">&nbsp;</div>
                    )}
                </td>
                <td className="border-x border-x-table-border text-center leading-7">{!!competitor && !!orderbooks && competitor.id.substring(28) in orderbooks && price in orderbooks[competitor.id.substring(28)]["b"] ? orderbooks[competitor.id.substring(28)]["b"][price] : ''}</td>
                <td className="border-x border-x-table-border text-center leading-7">{price}</td>
                <td className="border-x border-x-table-border text-center leading-7">{!!competitor && !!orderbooks && competitor.id.substring(28) in orderbooks && price in orderbooks[competitor.id.substring(28)]["a"] ? orderbooks[competitor.id.substring(28)]["a"][price] : ''}</td>
                <td className="text-center leading-7">
                {!!competitor && !!outstandings && competitor.id.substring(28) in outstandings && price in outstandings[competitor.id.substring(28)] && outstandings[competitor.id.substring(28)][price] < 0 ? (
                    <div {...(canTrade && { onClick: () => handleSubmit("ask", price) })} className="text-body-s relative flex h-full w-full items-center justify-center bg-lightgrey leading-7">
                      <button {...(canTrade && { onClick: (e) => { e.stopPropagation(); handleCancel("unask", price, outstandings[competitor.id.substring(28)][price] * -1)}})} className="group absolute right-[2px] top-[2px]">
                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                          <path
                            className="group-hover:fill-lightgreen"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M7.06888 1.63834C7.26415 1.44307 7.26415 1.12649 7.06888 0.931228C6.87362 0.735966 6.55704 0.735966 6.36178 0.931228L4.19238 3.10063L2.02297 0.931222C1.82771 0.73596 1.51113 0.735961 1.31587 0.931222C1.1206 1.12648 1.1206 1.44307 1.31587 1.63833L3.48527 3.80773L1.31587 5.97713C1.1206 6.17239 1.1206 6.48897 1.31587 6.68424C1.51113 6.8795 1.82771 6.8795 2.02297 6.68424L4.19237 4.51484L6.36178 6.68424C6.55704 6.8795 6.87362 6.8795 7.06889 6.68424C7.26415 6.48898 7.26415 6.1724 7.06889 5.97713L4.89948 3.80774L7.06888 1.63834Z"
                            fill="#A8ABAD"
                          />
                        </svg>
                      </button>
                      <span>{outstandings[competitor.id.substring(28)][price] * -1}</span>
                    </div>
                    ) : (
                      <div {...(canTrade && { onClick: () => handleSubmit("ask", price) })} className="text-body-s relative flex h-full w-full items-center justify-center bg-lightgrey leading-7">&nbsp;</div>
                    )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Trader;
