import { useEffect } from "react";
import { useWindowSize } from 'usehooks-ts'
import { useAtom } from "jotai";

import * as state from "../../../state";

import Competitors from './Competitors';
import Leaderboard from './Leaderboard';
import Markets from './Markets';
import OrderBook from './OrderBook';
import Overall from './Overall';
import Settlement from './Settlement';
import EnterTrade from './EnterTrade';
import TradeHistory from './TradeHistory';

function Trade({ websocket, showModal }) {
  const [admin] = useAtom(state.admin);

  const { width } = useWindowSize();

  useEffect(() => {
    document.title = "OOFTrade - Trade";
  }, []);

  return (
    <main className="mx-12 h-screen w-full sm:mx-5">
      <Markets websocket={websocket} showModal={showModal} />
      { width <= 992 && (
        <section className="mt-4 grid grid-cols-3 gap-4 pb-10 lg:grid-cols-2 md:grid-cols-1">
          <div className="flex flex-col gap-4">
            <Competitors websocket={websocket} />
            <OrderBook websocket={websocket} />
            {!!admin ? <Settlement /> : <EnterTrade websocket={websocket} />}
            <TradeHistory />
            <Leaderboard />
            <Overall />
          </div>
        </section>
      )}
      { width > 992 && width <= 1350 && (
        <section className="mt-4 grid grid-cols-3 gap-4 pb-10 lg:grid-cols-2 md:grid-cols-1">
          <div className="flex flex-col gap-4">
            <Competitors websocket={websocket} />
            <Overall />
          </div>
          <div className="flex flex-col gap-4">
            {!!admin ? <Settlement /> : <EnterTrade websocket={websocket} />}
            <OrderBook websocket={websocket} />
            <TradeHistory />
            <Leaderboard />
          </div>
        </section>
      )}
      { width > 1350 && (
        <section className="mt-4 grid grid-cols-3 gap-4 pb-10 lg:grid-cols-2 md:grid-cols-1">
          <div className="flex flex-col gap-4">
            <Competitors websocket={websocket} />
          </div>
          <div className="flex flex-col gap-4">
            <OrderBook websocket={websocket} />
            <Leaderboard />
          </div>
          <div className="flex flex-col gap-4">
            {!!admin ? <Settlement /> : <EnterTrade websocket={websocket} />}
            <TradeHistory />
            <Overall />
          </div>
        </section>
      )}
    </main>
  );
}

export default Trade;
