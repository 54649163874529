import { useAtom } from "jotai";

import * as state from "../../../../state";

import Admin from './Admin';
import Trader from './Trader';


function Competitors() {
  const [admin] = useAtom(state.admin);

  return !!admin ? <Admin /> : <Trader />;
}

export default Competitors;
