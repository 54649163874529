import { useEffect, useState } from 'react';
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useAtom } from "jotai";

import * as state from "../state";


function Signin() {
  const navigate = useNavigate();

  const [token, setToken] = useAtom(state.token);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [errorText, setErrorText] = useState("");

  const handleSubmit = (evt) => {
    setErrorText("");
    setSubmitted(true);
    evt.preventDefault();

    fetch(`${import.meta.env.VITE_API_URL}/signin`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "email": email,
        "password": password
      })
    })
      .then(r => r.json().then(json => ({ status: r.status, ok: r.ok, body: json })))
      .then(response => {
        if (!response.ok) {
          let err = new Error(`HTTP status code: ${response.status}`);
          err.status = response.status
          throw err
        }
        return response.body
      })
      .then(body => {
        localStorage.setItem("token", body.token);
        setToken(body.token);
        navigate("/trade");
      })
      .catch(() => {
        setErrorText("Invalid email or password");
        setSubmitted(false);
      });
  }

  useEffect(() => {
    document.title = 'OOFTrade - Signin';
  }, []);

  if (!!token) return <Navigate to="/trade" replace />;

  return (
    <>
      <header className="fixed top-[34px] left-0 w-full">
        <div className="mx-auto flex max-w-[1320px] items-center justify-between px-5">
          <a href="#">
            <img className="sm:max-w-[100px]" src="/img/logo.svg" alt="" />
          </a>
          <h4>Hello Guest</h4>
        </div>
      </header>
      <main className="flex-auto">
        <section className="flex h-screen flex-col items-center justify-center">
          <form autoComplete="off" className="w-full max-w-[356px]" onSubmit={handleSubmit}>
            <h2 className="mb-8 bg-green-text-gradient bg-clip-text text-transparent">
              OOFTrade
            </h2>
            <label htmlFor="email">
              <input
                className="input-large"
                placeholder="Email"
                name="email"
                id="email"
                type="text"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </label>
            <label htmlFor="password" className="input-label mt-6">
              <input
                className="input-large password-input"
                placeholder="Password"
                name="password"
                id="password"
                type={passwordShown ? "text" : "password"}
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
              <a onClick={() => setPasswordShown(!passwordShown)} className="toggle-password-btn absolute right-5 top-1/2 h-[18px] w-[18px] -translate-y-1/2">
                {passwordShown ? (
                  <img className="pswd-icon-hidden" src="/img/password-hidden.svg" alt="icon" />
                ) : (
                  <img className="pswd-icon-visible" src="/img/password-visible.svg" alt="icon" />
                )}
              </a>
            </label>
            <div className="mt-5 flex items-center justify-between">
              <Link to="/forgot" className="text-body-s text-graytext transition-colors hover:text-white">
                Forgot password?
              </Link>
            </div>
            {!!errorText && <p className="text-body-s-medium mt-6 text-lightred">{errorText}</p>}
            <button className="btn btn-large mt-[30px]" type="submit" disabled={submitted}>
              Sign In
            </button>
          </form>
          <p className="text-body-s mt-6 text-graytext">
            Don't have an account?
            <Link to="/signup" className="text-lightgreen transition-colors hover:text-lightgreen-hovered">
              Sign Up
            </Link>
          </p>
        </section>
      </main>
    </>
  );
}

export default Signin;
