import { useEffect, useState } from "react";
import { useAtom } from "jotai";

import * as state from "../../../../state";

function Admin() {
  const [token] = useAtom(state.token);

  const [tournament] = useAtom(state.tournament);
  const [competitor, setCompetitor] = useAtom(state.competitor);
  const [orderbooks] = useAtom(state.orderbooks);
  const [transactions] = useAtom(state.transactions);
  const [midmarket, setMidmarket] = useAtom(state.midmarket);

  const [ranking, setRanking] = useState(null);

  const handleSelect = (selected) => {
    setCompetitor(selected);
    setMidmarket(null);
  }

  useEffect(() => {
    if (!tournament || !transactions) return;

    const r = tournament.competitors.filter(c => c.settled === null).sort((a, b) => {
      if (a.id.substring(28) in transactions && transactions[a.id.substring(28)].length && b.id.substring(28) in transactions && transactions[b.id.substring(28)].length){
        return transactions[b.id.substring(28)][0].p - transactions[a.id.substring(28)][0].p;
      }
      else if (a.id.substring(28) in transactions && transactions[a.id.substring(28)].length){
        return -1;
      }
      else if (b.id.substring(28) in transactions && transactions[b.id.substring(28)].length){
        return 1;
      }
      else {
        return a.name - b.name;
      }
    })
    setRanking(r);
    if (!competitor && r.length){
      setCompetitor(r[0]);
    }
  }, [tournament, transactions]);

  return (
    <div className="rounded-lg bg-darkgrey py-4 px-3">
      <div className="h-full max-h-[600px] overflow-auto pr-3 lg:max-h-[850px]">
        <div className="flex gap-5 border-b border-b-table-border px-2 pb-2">
          <div className="w-[130px]">
            <p className="text-body-xs text-greytext">Team/Player</p>
          </div>
          <div className="text-body-xs grid w-[130px] grid-cols-3 gap-14 text-greytext">
            <p className="text-body-xs text-greytext">Last</p>
            <p className="text-body-xs text-greytext">Bid</p>
            <p className="text-body-xs text-greytext">Ask</p>
          </div>
        </div>
        <div className="mt-1 flex flex-col gap-1">
          {!!ranking && ranking.map((c, i) => (
            <div key={i} className={`live-row ${c.name.length % 2 === 0 ? "live-row-green" : "live-row-red"}`} {...(!!competitor && c.id !== competitor.id && { onClick: () => handleSelect(c) })}>
              <div className="w-[130px]">
                <p className={`text-body-s ${!!competitor && c.id === competitor.id && "text-lightblue"}`}>{c.name}</p>
              </div>
              <div className="text-body-s grid w-[130px] grid-cols-3 gap-14">
                <p className={`text-body-s ${!!transactions && c.id.substring(28) in transactions && transactions[c.id.substring(28)].length && transactions[c.id.substring(28)][0].a === "sell" ? "text-lightgreen" : "text-lightred"}`}>
                  {!!transactions && c.id.substring(28) in transactions && transactions[c.id.substring(28)].filter(t => !!t.b && !!t.s).length ? transactions[c.id.substring(28)].filter(t => !!t.b && !!t.s)[0].p : 0}
                </p>
                <p className={`text-body-s ${!!competitor && c.id === competitor.id && "text-lightblue"}`}>
                  {!!orderbooks && c.id.substring(28) in orderbooks && Object.keys(orderbooks[c.id.substring(28)].b).length ? Math.max(...Object.keys(orderbooks[c.id.substring(28)].b).map(k => parseFloat(k))) : 0}
                </p>
                <p className={`text-body-s ${!!competitor && c.id === competitor.id && "text-lightblue"}`}>
                  {!!orderbooks && c.id.substring(28) in orderbooks && Object.keys(orderbooks[c.id.substring(28)].a).length ? Math.min(...Object.keys(orderbooks[c.id.substring(28)].a).map(k => parseFloat(k))) : 0}
                </p>
              </div>
            </div>
          ))}
          {tournament && "competitors" in tournament && tournament.competitors.filter(c => c.settled !== null).sort((a, b) => b.settled - a.settled).map((c, i) => (
            <div key={i} className="live-row live-row-grey" {...(!!competitor && c.id !== competitor.id && { onClick: () => handleSelect(c) })}>
              <div className="w-[130px]">
                <p className="text-body-s text-greytext">{c.name}</p>
              </div>
              <div className="text-body-s grid w-[130px] grid-cols-3 gap-14">
                <p className="text-body-s col-span-1">{c.settled}</p>
                <p className="text-body-s col-span-2 text-center text-greytext">Settled</p>
              </div>
            </div>
          ))}
          </div>
      </div>
    </div>
  );
}

export default Admin;

