import { useEffect, useState } from "react";
import { useAtom } from "jotai";

import * as state from "../../../state";

function Overall() {
  const [username] = useAtom(state.username);

  const [tournament] = useAtom(state.tournament);
  const [transactions] = useAtom(state.transactions);
  const [pnls] = useAtom(state.pnls);

  const [players, setPlayers] = useState(null);
  const [ranking, setRanking] = useState(null);

  useEffect(() => {
    if (!tournament || !transactions || !pnls) {
      setPlayers(null);
      setRanking(null);
      return;
    }

    const totals = {};

    for (const c of tournament.competitors){
      if (!(c.id.substring(28) in transactions) || !(c.id.substring(28) in pnls)) continue;

      let last_price = 50;
      if (c.settled !== null) {
        last_price = c.settled;
      }
      else if (c.id.substring(28) in transactions && !!transactions[c.id.substring(28)].filter(t => !!t.b && !!t.s).length) {
        last_price = transactions[c.id.substring(28)].filter(t => !!t.b && !!t.s)[0].p;
      }

      for (const pnl of pnls[c.id.substring(28)]){
        if (!(pnl.u in totals)) totals[pnl.u] = {"username": pnl.u, "pnl": 0};
        totals[pnl.u]["pnl"] += pnl.b + pnl.p * last_price;
      }
    }

    setPlayers(Object.keys(totals).length);
    setRanking(
      Object.keys(totals).map(username => {
        return {
          "username": totals[username].username,
          "pnl": totals[username].pnl,
        }
      }).sort((a, b) => b.pnl - a.pnl)
    );
  }, [tournament, transactions, pnls]);

  return (
    <div className="rounded-lg bg-darkgrey">
      <p className="text-body-lg-m py-4 px-6 pb-3 text-left">Overall PnL Ranking</p>
      <div className="flex items-center justify-between bg-[#2C3638] px-6 py-[2px]">
        <p className="text-body-s text-greytext">Players: <span className="text-body-m-medium text-white">{!!players ? players : 0}</span></p>
        <p className="text-body-s text-greytext">Prize Pool: <span className="text-body-m-medium text-white">$0</span></p>
      </div>
      <div className="py-4 px-6 pt-2 pr-3">
        <div className="max-h-[220px] overflow-auto pr-3">
          <table className="overf-y-auto w-full table-auto">
            <thead className="text-body-xs border-b border-b-table-border text-greytext">
              <tr>
                <th className="w-20 py-[5px] px-[10px] text-left">Rank</th>
                <th className="py-[5px] px-[10px] text-left">Name</th>
                <th className="py-[5px] px-[10px] text-left">PnL</th>
                <th className="py-[5px] px-[10px] text-right">payout %</th>
              </tr>
            </thead>
            <tbody className="text-body-s overf-y-auto h-full max-h-[500px] w-full">
              {!!ranking && ranking.map((r, i) => (
                <tr key={i} className={`cursor-pointer overflow-auto rounded-lg ${r.username === username ? "text-lightblue" : ""} hover:bg-lightgrey `}>
                  <td className="py-[5px] px-[10px]">{i+1}</td>
                  <td className="py-[5px] px-[10px]">{r.username}</td>
                  <td className="py-[5px] px-[10px]">{r.pnl}</td>
                  <td className="py-[5px] px-[10px] text-right">-</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Overall;

