import { useEffect, useState } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import { useAtom } from "jotai";
import isStrongPassword from "validator/es/lib/isStrongPassword";

import { PASSWORD_REQUIREMENTS } from "../constants";
import * as state from "../state";


function Verify() {
  const [token] = useAtom(state.token);

  const params = useParams();

  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmShown, setConfirmShown] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [successText, setSuccessText] = useState("");
  const [errorText, setErrorText] = useState("");

  const handleSubmit = (evt) => {
    setSuccessText("");
    setErrorText("");
    setSubmitted(true);
    evt.preventDefault();

    if (password !== confirm || !isStrongPassword(password, PASSWORD_REQUIREMENTS)) {
      setErrorText("Invalid password");
      setSubmitted(false);
      return;
    }

    fetch(`${import.meta.env.VITE_API_URL}/verify`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "token": params.token,
        "password": password
      })
    })
      .then(r => r.json().then(json => ({ status: r.status, ok: r.ok, body: json })))
      .then(response => {
        if (!response.ok) {
          let err = new Error(`HTTP status code: ${response.status}`);
          err.status = response.status
          throw err
        }
        return response.body
      })
      .then(() => {
        setPassword("");
        setConfirm("");
        setSuccessText("Your password has been changed");
      })
      .catch(() => {
        setErrorText("Error, please try again");
        setSubmitted(false);
      });
  }

  useEffect(() => {
    document.title = 'OOFTrade - Verify';
  }, []);

  if (!!token) return <Navigate to="/trade" replace />;

  return (
    <>
      <header className="fixed top-[34px] left-0 w-full">
        <div className="mx-auto flex max-w-[1320px] items-center justify-between px-5">
          <a href="#">
            <img className="sm:max-w-[100px]" src="/img/logo.svg" alt="" />
          </a>
          <h4>Hello Guest</h4>
        </div>
      </header>
      <main className="flex-auto">
        <section className="grid h-screen grid-cols-2 overflow-hidden sm:grid-cols-1">
          <div className="relative h-screen sm:hidden">
            <img className="absolute left-0 top-0 z-[-1] h-full w-full object-cover" src="/img/auth-img.png" alt="image" />
          </div>
          <div className="flex flex-col items-center justify-center">
            <form autoComplete="off" className="w-full max-w-[356px]" onSubmit={handleSubmit}>
              <h2 className="mb-8 bg-green-text-gradient bg-clip-text text-transparent">
                Create Password
              </h2>
              <label htmlFor="password" className="input-label mt-6">
                <input
                  className="input-large password-input"
                  placeholder="Password"
                  name="password"
                  id="password"
                  type={passwordShown ? "text" : "password"}
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />
                <a onClick={() => setPasswordShown(!passwordShown)} className="toggle-password-btn absolute right-5 top-1/2 h-[18px] w-[18px] -translate-y-1/2">
                  {passwordShown ? (
                    <img className="pswd-icon-hidden" src="/img/password-hidden.svg" alt="icon" />
                  ) : (
                    <img className="pswd-icon-visible" src="/img/password-visible.svg" alt="icon" />
                  )}
                </a>
              </label>
              <label htmlFor="confirm" className="input-label mt-6">
                <input
                  className="input-large password-input"
                  name="confirm"
                  id="confirm"
                  placeholder="Confirm Password"
                  type={confirmShown ? "text" : "password"}
                  value={confirm}
                  onChange={e => setConfirm(e.target.value)}
                />
                <a onClick={() => setConfirmShown(!confirmShown)} className="toggle-password-btn absolute right-5 top-1/2 h-[18px] w-[18px] -translate-y-1/2">
                  {confirmShown ? (
                    <img className="pswd-icon-hidden" src="/img/password-hidden.svg" alt="icon" />
                  ) : (
                    <img className="pswd-icon-visible" src="/img/password-visible.svg" alt="icon" />
                  )}
                </a>
              </label>
              <p className="text-body-s-medium mt-6 text-graytext">Password must be at least 8 characters and include a lowercase, uppercase, number and symbol</p>
              {!!errorText && <p className="text-body-s-medium mt-6 text-lightred">{errorText}</p>}
              {!!successText && <p className="text-body-s-medium mt-6 text-lightgreen">{successText}</p>}
              <button className="btn btn-large mt-[30px]" type="submit">
                Continue
              </button>
            </form>
            <p className="text-body-s mt-6 text-graytext">
              Already have an account?
              <Link to="/" className="text-lightgreen transition-colors hover:text-lightgreen-hovered">
                Sign In
              </Link>
            </p>
          </div>
        </section>
      </main>
    </>
  );
}

export default Verify;
