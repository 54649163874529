import { useEffect, useState } from 'react';
import { Link, Navigate } from "react-router-dom";
import { useAtom } from "jotai";
import isEmail from 'validator/es/lib/isEmail';

import * as state from "../state";


function Forgot() {
  const [token] = useAtom(state.token);

  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [successText, setSuccessText] = useState("");
  const [errorText, setErrorText] = useState("");

  const handleSubmit = (evt) => {
    setSuccessText("");
    setErrorText("");
    setSubmitted(true);
    evt.preventDefault();

    if (!isEmail(email)) {
      setErrorText(`Invalid email ${email}`);
      setSubmitted(false);
      return;
    }

    fetch(`${import.meta.env.VITE_API_URL}/reset`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "email": email
      })
    })
      .then(r => r.json().then(json => ({ status: r.status, ok: r.ok, body: json })))
      .then(response => {
        if (!response.ok) {
          let err = new Error(`HTTP status code: ${response.status}`);
          err.status = response.status
          throw err
        }
        return response.body
      })
      .then(() => {
        setEmail("");
        setSuccessText("Check your email for instructions on creating your password");
      })
      .catch(() => {
        setErrorText("Error, please try again");
        setSubmitted(false);
      });
  }

  useEffect(() => {
    document.title = 'OOFTrade - Forgot';
  }, []);

  if (!!token) return <Navigate to="/trade" replace />;

  return (
    <>
      <header className="fixed top-[34px] left-0 w-full">
        <div className="mx-auto flex max-w-[1320px] items-center justify-between px-5">
          <a href="#">
            <img className="sm:max-w-[100px]" src="/img/logo.svg" alt="" />
          </a>
          <h4>Hello Guest</h4>
        </div>
      </header>
      <main className="flex-auto">
        <section className="grid h-screen grid-cols-2 overflow-hidden sm:grid-cols-1">
          <div className="relative h-screen sm:hidden">
            <img className="absolute left-0 top-0 z-[-1] h-full w-full object-cover" src="/img/auth-img.png" alt="image" />
          </div>
          <div className="flex flex-col items-center justify-center">
            <form autoComplete="off" className="w-full max-w-[356px]" onSubmit={handleSubmit}>
              <h2 className="mb-8 bg-green-text-gradient bg-clip-text text-transparent">
                Reset Password
              </h2>
              <label htmlFor="email" className="mt-6 block">
                <input
                  className="input-large"
                  placeholder="Email"
                  name="email"
                  id="email"
                  type="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </label>
              {!!errorText && <p className="text-body-s-medium mt-6 text-lightred">{errorText}</p>}
              {!!successText && <p className="text-body-s-medium mt-6 text-lightgreen">{successText}</p>}
              <button className="btn btn-large mt-[30px]" type="submit">
                Reset
              </button>
            </form>
            <p className="text-body-s mt-6 text-graytext">
              Don't have an account?
              <Link to="/signup" className="text-lightgreen transition-colors hover:text-lightgreen-hovered">
                Sign Up
              </Link>
            </p>
            <p className="text-body-s mt-2 text-graytext">
              Already have an account?
              <Link to="/" className="text-lightgreen transition-colors hover:text-lightgreen-hovered">
                Sign In
              </Link>
            </p>
          </div>
        </section>
      </main>
    </>
  );
}

export default Forgot;
