import { useAtom } from "jotai";

import * as state from "../../../../state";

import Admin from './Admin';
import Trader from './Trader';


function Markets({ websocket, showModal }) {
  const [admin] = useAtom(state.admin);

  return !!admin ? <Admin websocket={websocket} showModal={showModal} /> : <Trader websocket={websocket} />;
}

export default Markets;
