import { useAtom } from "jotai";

import * as state from "../../../../state";
import { WEBSOCKET_ACTION } from "../../../../constants";

function Admin({ websocket, showModal }) {
  const [token] = useAtom(state.token);

  const [tournaments, setTournaments] = useAtom(state.tournaments);
  const [tournament, setTournament] = useAtom(state.tournament);
  const [competitor, setCompetitor] = useAtom(state.competitor);
  const [positions, setPositions] = useAtom(state.positions);
  const [outstandings, setOutstandings] = useAtom(state.outstandings);
  const [sequences, setSequences] = useAtom(state.sequences);
  const [orderbooks, setOrderbooks] = useAtom(state.orderbooks);
  const [transactions, setTransactions] = useAtom(state.transactions);
  const [pnls, setPnls] = useAtom(state.pnls);
  const [midmarket, setMidmarket] = useAtom(state.midmarket);

  const handleUpdate = (id, market_open) => {
    fetch(`${import.meta.env.VITE_API_URL}/tournaments/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": token
      },
      body: JSON.stringify({
        "type": market_open ? "open" : "close"
      })
    })
      .then(r => r.json().then(json => ({ status: r.status, ok: r.ok, body: json })))
      .then(response => {
        if (!response.ok) {
          let err = new Error(`HTTP status code: ${response.status}`);
          err.status = response.status
          throw err
        }
        return response.body
      })
      .then(json => {
        setTournaments(json.tournaments);
        const t = json.tournaments.find(t => t.id === id);
        setTournament(t);
        setCompetitor(t.competitors.find(c => c.id === competitor.id));
      });
  }

  const handleSelect = (tournament) => {
    setPositions(null);
    setOutstandings(null);
    setSequences(null);
    setOrderbooks(null);
    setTransactions(null);
    setPnls(null);
    setMidmarket(null);
    setTournament(tournament);
    setCompetitor(null);
  }

  return (
    <section className="mt-6">
      <div className="flex items-center justify-between sm:flex-col-reverse sm:gap-2">
        <p className="text-body-lg-m">Tournaments:</p>
        {!!tournament && (!!tournament.market_open ? <button onClick={() => handleUpdate(tournament.id, !tournament.market_open)} className="market-status open">Market Open</button> : <button onClick={() => handleUpdate(tournament.id, !tournament.market_open)} className="market-status closed">Market Closed</button>)}
      </div>
      <div className="border-b border-b-[#3C4547] py-[18px] sm:py-2">
        <div className="flex gap-4 sm:flex-wrap sm:gap-1">
          {!!tournaments && tournaments.map((t, i) => (
            <button key={i} className={`tournament-button ${!!tournament && t.id === tournament.id && "active"}`} {...(!!tournament && t.id !== tournament.id && { onClick: () => handleSelect(t)})}>
              <img src={`${import.meta.env.VITE_CDN_URL}/tournaments/logos/${t.id}`} style={{height: "30px"}} className="sm:h-5 sm:w-5" />
              <span>{t.name}</span>
            </button>
          ))}
          <button onClick={() => showModal("create_market")} className="ml-auto flex h-[54px] w-[54px] items-center justify-center rounded-lg border border-transparent bg-darkgrey p-[18px] text-xl hover:border-lightgreen sm:h-5 sm:w-5">
            <span>+</span>
          </button>
        </div>
      </div>
    </section>
  );
}

export default Admin;
