import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { formatDistance } from"date-fns";

import * as state from "../../../state";

function TradeHistory() {
  const [username] = useAtom(state.username);

  const [competitor] = useAtom(state.competitor);
  const [transactions] = useAtom(state.transactions);

  const [now, setNow] = useState(new Date());

  useEffect(() => {
    const intervalID = setInterval(() => setNow(new Date()), 1000);
    return () => clearInterval(intervalID);
  }, []);

  return (
    <div className="rounded-lg bg-darkgrey py-4 px-6 pr-3">
      <p className="text-body-lg-m pb-3 text-left">Trade History</p>
      <div className="max-h-[300px] overflow-auto pr-3">
        <table className="w-full table-auto">
          <thead className="text-body-xs border-b border-b-table-border text-greytext">
            <tr>
              <th className="py-[5px] px-[10px] text-left">Time</th>
              <th className="py-[5px] px-[10px] text-left">Buyer</th>
              <th className="py-[5px] px-[10px] text-left">Seller</th>
              <th className="py-[5px] px-[10px] text-left">Price</th>
              <th className="py-[5px] px-[10px] text-right">Quantity</th>
            </tr>
          </thead>
          <tbody className="text-body-s overf-y-auto h-full max-h-[500px] w-full">
            {!!transactions && !!competitor && competitor.id.substring(28) in transactions && transactions[competitor.id.substring(28)].map((t, i) => (
              <tr key={i} className={`cursor-pointer overflow-auto rounded-lg ${t.b === username || t.s === username ? "text-lightblue" : ""} hover:bg-lightgrey `}>
                <td className="py-[5px] px-[10px]">{formatDistance(new Date(t.t), now)}</td>
                <td className="py-[5px] px-[10px]">{t.b}</td>
                <td className="py-[5px] px-[10px]">{t.s}</td>
                <td className="py-[5px] px-[10px]">{t.p}</td>
                <td className="py-[5px] px-[10px] text-right">{t.q}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TradeHistory;

