import { useAtom } from "jotai";

import * as state from "../../../../state";

import Admin from './Admin';
import Trader from './Trader';


function OrderBook({ websocket }) {
  const [admin] = useAtom(state.admin);

  return !!admin ? <Admin /> : <Trader websocket={websocket} />;
}

export default OrderBook;
