const PASSWORD_REQUIREMENTS = {
  minLength: 8,
  minLowercase: 1,
  minUppercase: 1,
  minNumbers: 1,
  minSymbols: 1,
}  
const WEBSOCKET_ACTION = {
  CONNECT: "connect",
  SUBSCRIBE: "subscribe",
  UNSUBSCRIBE: "unsubscribe",
  ORDER: "order",
}
const ORDER_ACTION = {
  BID: "bid",
  ASK: "ask",
  UNBID: "unbid",
  UNASK: "unask",
}
const WEBSOCKET_UPDATE = {
  CONNECT: "connect",
  TOURNAMENTS: "tournaments",
  TOURNAMENT: "tournament",
  ERROR: "error",
}

export {
  PASSWORD_REQUIREMENTS,
  WEBSOCKET_ACTION,
  ORDER_ACTION,
  WEBSOCKET_UPDATE,
};

