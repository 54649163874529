import { atom } from "jotai";

export const token = atom(localStorage.getItem("token"));

export const email = atom(null);
export const username = atom(null);
export const firstname = atom(null);
export const lastname = atom(null);
export const admin = atom(false);
export const avatar = atom(false);
export const enrolled = atom([]);

export const tournaments = atom([]);
export const tournament = atom(null);
export const competitor = atom(null);
export const positions = atom(null);
export const outstandings = atom(null);
export const sequences = atom(null);
export const orderbooks = atom(null);
export const transactions = atom(null);
export const pnls = atom(null);
export const midmarket = atom(null);
