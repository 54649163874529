import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import { useAtom } from "jotai";
import ReconnectingWebSocket from 'reconnecting-websocket';

import * as state from "../../state";
import { WEBSOCKET_ACTION, WEBSOCKET_UPDATE } from "../../constants";

import Trade from "./Trade";
import Sidebar from "./Sidebar";

import CreateMarket from "./Modals/CreateMarket";
import Settings from "./Modals/Settings";

let websocket = null;

function Main({ selected }) {
  const [token, setToken] = useAtom(state.token);
  const [email, setEmail] = useAtom(state.email);
  const [username, setUsername] = useAtom(state.username);
  const [firstname, setFirstname] = useAtom(state.firstname);
  const [lastname, setLastname] = useAtom(state.lastname);
  const [admin, setAdmin] = useAtom(state.admin);
  const [avatar, setAvatar] = useAtom(state.avatar);
  const [enrolled, setEnrolled] = useAtom(state.enrolled);

  const [tournaments, setTournaments] = useAtom(state.tournaments);
  const [tournament, setTournament] = useAtom(state.tournament);
  const [competitor, setCompetitor] = useAtom(state.competitor);
  const [positions, setPositions] = useAtom(state.positions);
  const [outstandings, setOutstandings] = useAtom(state.outstandings);
  const [sequences, setSequences] = useAtom(state.sequences);
  const [orderbooks, setOrderbooks] = useAtom(state.orderbooks);
  const [transactions, setTransactions] = useAtom(state.transactions);
  const [pnls, setPnls] = useAtom(state.pnls);

  const [modalShown, setModalShown] = useState(null);
  const [message, setMessage] = useState(null);
 
  const showModal = (type) => {
    document.body.classList.add("overflow-hidden");
    document.body.classList.add("pr-[6px]");
    setModalShown(type);
  }

  const hideModal = () => {
    document.body.classList.remove("overflow-hidden");
    document.body.classList.remove("pr-[6px]");
    setModalShown(null);
  }

  const onOpen = (event) => {
    websocket.send(JSON.stringify({
      "token": token,
      "action": WEBSOCKET_ACTION.CONNECT,
      "data": {}
    }));
  };

  const onMessage = (event) => {
    setMessage(JSON.parse(event.data));
  };

  useEffect(() => {
    if (!message) return;

    let t, c;
    switch (message.type) {
      case WEBSOCKET_UPDATE.CONNECT:
        setEmail(message.data.email);
        setUsername(message.data.username);
        Sentry.setUser({ username: message.data.username });
        setFirstname(message.data.firstname);
        setLastname(message.data.lastname);
        setAdmin(message.data.admin);
        setAvatar(message.data.avatar);
        setEnrolled(message.data.enrolled);
        setTournaments(message.data.tournaments);
        t = !!tournament ? message.data.tournaments.find(t => t.id === tournament.id) : (!message.data.admin ? message.data.tournaments.find(t => message.data.enrolled.includes(t.id)) : message.data.tournaments[0]);
        setTournament(t);
        if (!!t && !!competitor && t.competitors.filter(c => c.id === competitor.id).length){
          setCompetitor(t.competitors.find(c => c.id === competitor.id));
        }
        break;
      case WEBSOCKET_UPDATE.TOURNAMENTS:
        setTournaments(message.data.tournaments);
        t = !!tournament ? message.data.tournaments.find(t => t.id === tournament.id) : (!admin ? message.data.tournaments.find(t => enrolled.includes(t.id)) : message.data.tournaments[0]);
        setTournament(t);
        if (!!t && !!competitor && t.competitors.filter(c => c.id === competitor.id).length){
          setCompetitor(t.competitors.find(c => c.id === competitor.id));
        }
        break;
      case WEBSOCKET_UPDATE.TOURNAMENT:
        setPositions(message.data.positions);
        setOutstandings(message.data.outstandings);
        setSequences(message.data.sequences);
        setOrderbooks(message.data.orderbooks);
        setTransactions(message.data.transactions);
        setPnls(message.data.pnls);
        break;
      case WEBSOCKET_UPDATE.ERROR:
        if (message.data.message === "Unauthorized") {
          setTournaments([]);
          setTournament(null);
          setCompetitor(null);
          setPositions(null);
          setOutstandings(null);
          setSequences(null);
          setOrderbooks(null);
          setTransactions(null);

          setEmail(null);
          setUsername(null);
          Sentry.setUser(null);
          setFirstname(null);
          setLastname(null);
          setAdmin(false);
          setAvatar(false);
          setEnrolled([]);

          localStorage.removeItem("token");
          setToken(null);
          break;
        }
      }
  }, [message]);

  useEffect(() => {
    if (!websocket || !tournament) return;

    websocket.send(JSON.stringify({
      "token": token,
      "action": WEBSOCKET_ACTION.SUBSCRIBE,
      "data": {
        "tournament": tournament.id
      }
    }));
  }, [websocket, tournament]);

  useEffect(() => {
    if (!token) return;

    websocket = new ReconnectingWebSocket(import.meta.env.VITE_WS_URL)
    websocket.addEventListener('open', onOpen);
    websocket.addEventListener('message', onMessage);

    return () => {
      websocket.removeEventListener('message', onMessage);
      websocket.removeEventListener('open', onOpen);
      websocket.close();
      websocket = null;
    };
  }, [token]);

  if (!token) return <Navigate to="/" replace />;

  return (
    <>
      <div className="wrapper relative flex min-h-full">
        <Sidebar showModal={showModal} />
        {selected == "trade" && <Trade websocket={websocket} showModal={showModal} />}
      </div>
      {modalShown === "settings" && <Settings hideModal={hideModal} />}
      {!!admin && modalShown === "create_market" && <CreateMarket websocket={websocket} hideModal={hideModal} />}
    </>
  );
}

export default Main;
