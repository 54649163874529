import { createBrowserRouter, RouterProvider } from "react-router-dom";

import './index.css';

import Main from "./components/Main";
import Forgot from "./components/Forgot";
import Signin from "./components/Signin";
import Signup from "./components/Signup";
import Verify from "./components/Verify";

function App() {
  const router = createBrowserRouter([
    {
      path: "/trade",
      element: <Main selected="trade" />,
    },
    {
      path: "/signup",
      element: <Signup />,
    },
    {
      path: "/forgot",
      element: <Forgot />,
    },
    {
      path: "/verify/:token",
      element: <Verify />,
    },
    {
      path: "/",
      element: <Signin />,
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;