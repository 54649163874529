import { useEffect, useRef } from "react";
import { useAtom } from "jotai";

import * as state from "../../../../state";

function Admin() {
  const [competitor] = useAtom(state.competitor);
  const [orderbooks] = useAtom(state.orderbooks);
  const [midmarket, setMidmarket] = useAtom(state.midmarket);

  const midmarketRef = useRef();

  useEffect(() => {
    if (!midmarket) return;

    const {current} = midmarketRef
    if (current !== null){
      current.scrollIntoView({ behavior: "smooth",   block: "center" })
    }

  }, [midmarket]);

  useEffect(() => {
    if (!!midmarket) return;

    let bid, ask;
    if (!!orderbooks && !!competitor && competitor.id.substring(28) in orderbooks && "b" in orderbooks[competitor.id.substring(28)] && Object.keys(orderbooks[competitor.id.substring(28)].b).length){
      bid = Math.max(...Object.keys(orderbooks[competitor.id.substring(28)].b).map(k => parseFloat(k)));
    }
    if (!!orderbooks && !!competitor && competitor.id.substring(28) in orderbooks && "a" in orderbooks[competitor.id.substring(28)] && Object.keys(orderbooks[competitor.id.substring(28)].a).length){
      ask = Math.max(...Object.keys(orderbooks[competitor.id.substring(28)].a).map(k => parseFloat(k)));
    }

    if (!!bid && !!ask){
      setMidmarket(Math.ceil((bid * 2 + ask * 2) / 2) / 2);
    }
    else if (!!bid){ 
      setMidmarket(bid);
    }
    else if (!!ask){
      setMidmarket(ask);
    }

  }, [orderbooks, competitor, midmarket]);

  return (
    <div className="rounded-lg bg-darkgrey py-4 px-6 pr-3">
      <div className="flex justify-between">
        <p className="text-body-lg-m pb-3 text-left">{!!competitor && competitor.name} Order Book</p>
      </div>
      <div className="max-h-[429px] overflow-auto pr-3">
        <table className="overf-y-auto w-full table-fixed border-collapse">
          <thead className="text-body-xs border-b border-b-table-border text-greytext">
            <tr>
              <th className="py-[5px] px-[10px] text-center">Bid</th>
              <th className="border-x border-x-table-border py-[5px] px-[10px] text-center">
                Price
              </th>
              <th className="py-[5px] px-[10px] text-center">Ask</th>
            </tr>
          </thead>
          <tbody className="text-body-s overf-y-auto h-full max-h-[500px] w-full">
            {Array.from({length: 199}, (_, i) => (i + 1) / 2).reverse().map(price => (
              <tr key={price} {...(price === midmarket && { ref: midmarketRef })} className="overflow-auto rounded-lg border-y border-y-table-border">
                <td className="text-center leading-7">{!!competitor && !!orderbooks && competitor.id.substring(28) in orderbooks && price in orderbooks[competitor.id.substring(28)]["b"] ? orderbooks[competitor.id.substring(28)]["b"][price] : ''}</td>
                <td className="border-x border-x-table-border text-center leading-7">{price}</td>
                <td className="text-center leading-7">{!!competitor && !!orderbooks && competitor.id.substring(28) in orderbooks && price in orderbooks[competitor.id.substring(28)]["a"] ? orderbooks[competitor.id.substring(28)]["a"][price] : ''}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>

  );
}

export default Admin;
