import { useRef, useState } from "react";
import { useAtom } from "jotai";
import isLength from 'validator/es/lib/isLength';
import isMimeType from 'validator/es/lib/isMimeType';
import isStrongPassword from "validator/es/lib/isStrongPassword";

import * as state from "../../../state";
import { PASSWORD_REQUIREMENTS } from "../../../constants";


function Settings({ hideModal }) {
  const [token] = useAtom(state.token);

  const [email] = useAtom(state.email);
  const [username] = useAtom(state.username);
  const [firstname, setFirstname] = useAtom(state.firstname);
  const [lastname, setLastname] = useAtom(state.lastname);
  const [avatar, setAvatar] = useAtom(state.avatar);

  const [image, setImage] = useState(null)
  const [imageObjectURL, setImageObjectURL] = useState(null)
  const [firstnameInput, setFirstnameInput] = useState(firstname || "");
  const [lastnameInput, setLastnameInput] = useState(lastname || "");
  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmShown, setConfirmShown] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [errorText, setErrorText] = useState("");

  const inputFile = useRef(null);

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(event.target.files[0]);
      setImageObjectURL(URL.createObjectURL(event.target.files[0]));
    }
  }

  const handleReset = () => {
    setFirstnameInput(firstname || "");
    setLastnameInput(lastname || "");
    setPassword("");
    setConfirm("");
  }

  const handleSubmit = (evt) => {
    setErrorText("");
    setSubmitted(true);
    evt.preventDefault();

    if (!!image && (!isMimeType(image.type) || !image.type.match(/image\/(jpeg|png)/))) {
      setErrorText("Please select a valid image");
      setSubmitted(false);
      return;
    }

    if ((!!password || !!confirm) && (password !== confirm || !isStrongPassword(password, PASSWORD_REQUIREMENTS))) {
      setErrorText("Invalid password");
      setSubmitted(false);
      return;
    }

    fetch(`${import.meta.env.VITE_API_URL}/settings`, {
      method: "PUT",
      headers: {
        'Content-Type': 'application/json',
        'X-API-Key': token
      },
      body: JSON.stringify({
        "firstname": firstnameInput,
        "lastname": lastnameInput,
        "password": !!password ? password : null,
        "content-type": !!image ? image.type : null
      })
    })
      .then(r => r.json().then(json => ({ status: r.status, ok: r.ok, body: json })))
      .then(response => {
        if (!response.ok) {
          let err = new Error(`HTTP status code: ${response.status}`);
          err.status = response.status
          throw err
        }
        return response.body
      })
      .then(json => {
        if (!image) return { ok: true, body: {...json, avatar: !!avatar }}
        const formData  = new FormData();
        for (const key in json.upload.fields) {
          formData.append(key, json.upload.fields[key]);
        }
        formData.append('Content-Type', image.type);
        formData.append("file", image);
        return fetch(json.upload.url, {
          method: "POST",
          body: formData
        }).then(r => ({ status: r.status, ok: r.ok, body: {...json, avatar: true }}));
      })
      .then(response => {
        if (!response.ok) {
          let err = new Error(`HTTP status code: ${response.status}`);
          err.status = response.status
          throw err
        }
        return response.body
      })
      .then(json => {
        setFirstname(json.firstname);
        setLastname(json.lastname);
        setAvatar(json.avatar);
        hideModal();
      })
      .catch(() => {
        setSubmitted(false);
      });


  }

  return (
    <div className="modal fixed flex left-0 top-0 z-10 h-screen w-screen justify-center bg-black/60 pt-[52px]">
      <div className="relative mx-auto max-h-[700px] w-full max-w-[528px] overflow-auto rounded-md bg-darkgrey p-6">
        <div className="flex h-full flex-col">
          <p className="text-body-lg-m text-center">Account Settings</p>
          <div className="mt-6 flex h-full gap-6 sm:flex-col">
            <div className="relative h-[100px] w-[100px] shrink-0 rounded-full">
              <img src={!!imageObjectURL ? imageObjectURL : "/img/account-avatar.svg"} />
              <button onClick={() => inputFile.current.click()} className="absolute left-1/2 -bottom-5 -translate-x-1/2">
                <img src="/img/set-photo.svg" />
              </button>
              <input type="file" ref={inputFile} onChange={onImageChange} className="hidden" />
            </div>
            <div className="h-full w-full">
              <form className="flex h-full flex-col gap-3" onSubmit={handleSubmit}>
                <label className="input-label" htmlFor="email">
                  <p className="text-body-m text-greytext">Email</p>
                  <input
                    id="email"
                    name="email"
                    type="text"
                    className="input-small p-3"
                    value={email}
                    disabled={true}
                  />
                </label>
                <label className="input-label" htmlFor="firstName">
                  <p className="text-body-m text-greytext">First Name</p>
                  <input
                    id="firstName"
                    name="firstName"
                    type="text"
                    className="input-small p-3"
                    value={firstnameInput}
                    onChange={e => setFirstnameInput(e.target.value)}
                  />
                </label>
                <label className="input-label" htmlFor="lastName">
                  <p className="text-body-m text-greytext">Last Name</p>
                  <input
                    id="lastName"
                    name="lastName"
                    type="text"
                    className="input-small p-3"
                    value={lastnameInput}
                    onChange={e => setLastnameInput(e.target.value)}
                  />
                </label>
                <label className="input-label" htmlFor="acronym">
                  <p className="text-body-m text-greytext">Username</p>
                  <input
                    id="username"
                    name="username"
                    type="text"
                    className="input-small p-3"
                    value={username}
                    disabled={true}
                  />
                </label>
                <p className="text-body-m text-greytext">Password</p>
                <label htmlFor="password" className="input-label -mt-2">
                  <input
                    className="input-small password-input p-3"
                    name="password"
                    id="password"
                    type={passwordShown ? "text" : "password"}
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                  />
                  <a onClick={() => setPasswordShown(!confirmShown)} className="toggle-password-btn absolute right-5 top-1/2 h-[18px] w-[18px] -translate-y-1/2">
                    {passwordShown ? (
                      <img className="pswd-icon-hidden" src="/img/password-hidden.svg" alt="icon" />
                    ) : (
                      <img className="pswd-icon-visible" src="/img/password-visible.svg" alt="icon" />
                    )}
                  </a>
                </label>
                <p className="text-body-m text-greytext">Confirm Password</p>
                <label htmlFor="passwordConfirm" className="input-label -mt-2">
                  <input
                    className="input-small password-input p-3"
                    name="confirm"
                    id="confirm"
                    type={confirmShown ? "text" : "password"}
                    value={confirm}
                    onChange={e => setConfirm(e.target.value)}
                  />
                  <a onClick={() => setConfirmShown(!confirmShown)} className="toggle-password-btn absolute right-5 top-1/2 h-[18px] w-[18px] -translate-y-1/2">
                    {confirmShown ? (
                      <img className="pswd-icon-hidden" src="/img/password-hidden.svg" alt="icon" />
                    ) : (
                      <img className="pswd-icon-visible" src="/img/password-visible.svg" alt="icon" />
                    )}
                  </a>
                </label>
                {!!errorText && <p className="text-body-s-medium mt-6 text-lightred">{errorText}</p>}
                <div className="mt-auto grid grid-cols-2 gap-[14px]">
                  <button className="btn btn-blue p-[13px]" type="button" onClick={handleReset}>Reset</button>
                  <button className="btn p-[13px]" type="submit" disabled={submitted}>Update</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <button className="group absolute right-3 top-3" onClick={hideModal}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="12" fill="#313A3D" />
            <path
              className="transition-colors group-hover:fill-lightgreen"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16.7891 8.02444C17.0234 7.79013 17.0234 7.41023 16.7891 7.17591C16.5548 6.9416 16.1749 6.9416 15.9406 7.17591L11.9826 11.1339L8.02446 7.17579C7.79014 6.94147 7.41025 6.94147 7.17593 7.17579C6.94162 7.4101 6.94162 7.79 7.17593 8.02431L11.1342 11.9826L7.17593 15.9409C6.94162 16.1753 6.94162 16.5552 7.17593 16.7895C7.41024 17.0238 7.79014 17.0238 8.02446 16.7895L11.9825 12.8315L15.9406 16.7896C16.1749 17.0239 16.5548 17.0239 16.7891 16.7896C17.0234 16.5553 17.0234 16.1754 16.7891 15.9411L12.8308 11.9828L16.7891 8.02444Z"
              fill="#A8ABAD"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}

export default Settings;

