import { useRef, useState } from 'react';
import { useAtom } from "jotai";
import isInt from 'validator/es/lib/isInt';
import isMimeType from 'validator/es/lib/isMimeType';

import * as state from "../../../state";
import { WEBSOCKET_ACTION } from "../../../constants";


function CreateMarket({ websocket, hideModal }) {
  const [token] = useAtom(state.token);

  const [tournaments, setTournaments] = useAtom(state.tournaments);
  const [tournament, setTournament] = useAtom(state.tournament);
  const [competitor, setCompetitor] = useAtom(state.competitor);
  const [midmarket, setMidmarket] = useAtom(state.midmarket);

  const [image, setImage] = useState(null)
  const [imageObjectURL, setImageObjectURL] = useState(null)
  const [name, setName] = useState("");
  const [limit, setLimit] = useState("");
  const [competitorName, setCompetitorName] = useState("");
  const [competitorNames, setCompetitorNames] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [errorText, setErrorText] = useState("");

  const inputFile = useRef(null);

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(event.target.files[0]);
      setImageObjectURL(URL.createObjectURL(event.target.files[0]));
    }
  }

  const handleSubmit = (evt) => {
    setErrorText("");
    setSubmitted(true);
    evt.preventDefault();

    if (!image) {
      setErrorText("Please select a logo image");
      setSubmitted(false);
      return;
    }

    if (!isMimeType(image.type) || !image.type.match(/image\/(jpeg|png)/)) {
      setErrorText("Please select a valid image");
      setSubmitted(false);
      return;
    }

    if (!name) {
      setErrorText("Please input a tournament name");
      setSubmitted(false);
      return;
    }

    if (!isInt(limit, {gt: 1})) {
      setErrorText("Please input a valid limit");
      setSubmitted(false);
      return;
    }

    if (!!competitorNames.filter(c => !c.length).length || competitorNames.length < 2) {
      setErrorText("Please input 2 or more valid team names");
      setSubmitted(false);
      return;
    }

    fetch(`${import.meta.env.VITE_API_URL}/tournaments`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'X-API-Key': token
      },
      body: JSON.stringify({
        "name": name,
        "limit": parseInt(limit, 10),
        "competitors": competitorNames,
        "content-type": image.type,
      })
    })
      .then(r => r.json().then(json => ({ status: r.status, ok: r.ok, body: json })))
      .then(response => {
        if (!response.ok) {
          let err = new Error(`HTTP status code: ${response.status}`);
          err.status = response.status
          throw err
        }
        return response.body
      })
      .then(json => {
        const formData  = new FormData();
        for (const key in json.upload.fields) {
          formData.append(key, json.upload.fields[key]);
        }
        formData.append('Content-Type', image.type);
        formData.append("file", image);
        return fetch(json.upload.url, {
          method: "POST",
          body: formData
        }).then(r => ({ status: r.status, ok: r.ok, body: json }));
      })
      .then(response => {
        if (!response.ok) {
          let err = new Error(`HTTP status code: ${response.status}`);
          err.status = response.status
          throw err
        }
        return response.body
      })
      .then(json => 
        fetch(`${import.meta.env.VITE_API_URL}/tournaments/${json.id}`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            "X-API-Key": token
          },
          body: JSON.stringify({
            "type": "create"
          })
        })
      )
      .then(r => r.json().then(json => ({ status: r.status, ok: r.ok, body: json })))
      .then(response => {
        if (!response.ok) {
          let err = new Error(`HTTP status code: ${response.status}`);
          err.status = response.status
          throw err
        }
        return response.body
      })
      .then(json => {
        setTournaments(json.tournaments);
        const tournament = json.tournaments.find(t => t.id === json.id);
        setTournament(tournament);
        setCompetitor(null);
        setMidmarket(null);
        hideModal();
      })
      .catch(() => {
        setSubmitted(false);
      });
  }

  return (
      <div className="modal fixed left-0 top-0 z-10 h-screen w-screen justify-center overflow-auto bg-black/60 pt-[52px]">
      <div className="relative mx-auto h-[700px] max-w-[528px] rounded-md bg-darkgrey p-6 pr-3">
        <div className="flex h-full flex-col">
          <p className="text-body-lg-m text-center">Create Tournament</p>
          <div className="mt-6 flex h-full gap-6 sm:flex-col">
            <div className="relative h-[100px] w-[100px] shrink-0 rounded-lg">
              <img src="/img/photo-bg.svg" />
              <img className="absolute left-1/2 top-1/2 h-[56px] w-[56px] -translate-x-1/2 -translate-y-1/2" src={!!imageObjectURL ? imageObjectURL : "/img/photo-icon.svg"} />
              <button onClick={() => inputFile.current.click()} className="absolute left-1/2 -bottom-5 -translate-x-1/2">
                <img src="/img/set-photo.svg" />
              </button>
              <input type="file" ref={inputFile} onChange={onImageChange} className="hidden" />
            </div>
            <div className="h-full w-full">
              <form className="flex h-full flex-col gap-3" onSubmit={handleSubmit}>
                <label className="input-label pr-3" htmlFor="name">
                  <p className="text-body-m text-greytext">Tournament Name</p>
                  <input
                    id="name"
                    name="name"
                    type="text"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    className="input-small p-3"
                  />
                </label>
                <label className="input-label pr-3" htmlFor="limit">
                  <p className="text-body-m text-greytext">Position Limit</p>
                  <input
                    id="limit"
                    name="limit"
                    type="text"
                    value={limit}
                    onChange={e => setLimit(e.target.value)}
                    className="input-small p-3"
                  />
                </label>
                <div className="pr-3">
                  <hr className="my-3 block h-[1px] w-full border-none bg-table-border" />
                </div>
                <label className="input-label flex-row items-center gap-5 pr-3" htmlFor="new-team">
                  <p className="text-body-m text-greytext">Teams</p>
                  <input
                    id="new-team"
                    name="new-team"
                    type="text"
                    value={competitorName}
                    onChange={e => setCompetitorName(e.target.value)}
                    className="input-small pr-12"
                  />
                  <a onClick={() => {setCompetitorNames([...competitorNames, competitorName]); setCompetitorName("")}} className="absolute top-[3px] right-[15px] ml-auto flex h-[30px] w-[30px] items-center justify-center rounded-md border border-transparent bg-[#2C3638] p-[7px] text-xl hover:border-lightgreen">
                    <span>+</span>
                  </a>
                </label>
                <div className="max-h-[284px] overflow-y-auto py-2 pr-3">
                  <ul className="flex flex-col gap-1">
                    {competitorNames.map((c, i) => (
                      <li key={i} className="flex justify-between rounded-md bg-lightgrey py-2 px-3">
                        <span className="text-body-s">{c}</span>
                        <a onClick={() => setCompetitorNames([...competitorNames.slice(0, i), ...competitorNames.slice(i+1, competitorNames.length)])} className="group">
                          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              className="transition-colors group-hover:fill-lightgreen"
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M10.7891 2.02444C11.0234 1.79013 11.0234 1.41023 10.7891 1.17591C10.5548 0.9416 10.1749 0.9416 9.94058 1.17591L5.98258 5.13391L2.02446 1.17579C1.79014 0.941471 1.41025 0.941472 1.17593 1.17579C0.941616 1.4101 0.941616 1.79 1.17593 2.02431L5.13424 5.98263L1.17593 9.94094C0.941616 10.1753 0.941616 10.5552 1.17593 10.7895C1.41024 11.0238 1.79014 11.0238 2.02446 10.7895L5.98245 6.83147L9.94058 10.7896C10.1749 11.0239 10.5548 11.0239 10.7891 10.7896C11.0234 10.5553 11.0234 10.1754 10.7891 9.94107L6.83079 5.98276L10.7891 2.02444Z"
                              fill="#A8ABAD"
                            />
                          </svg>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                {!!errorText && <p className="text-body-s-medium mt-6 text-lightred">{errorText}</p>}
                <button className="btn btn-small mt-auto flex" type="submit" disabled={submitted}>
                  Create
                </button>
              </form>
            </div>
          </div>
        </div>
        <button onClick={hideModal} className="group absolute right-3 top-3">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="12" fill="#313A3D" />
            <path
              className="transition-colors group-hover:fill-lightgreen"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16.7891 8.02444C17.0234 7.79013 17.0234 7.41023 16.7891 7.17591C16.5548 6.9416 16.1749 6.9416 15.9406 7.17591L11.9826 11.1339L8.02446 7.17579C7.79014 6.94147 7.41025 6.94147 7.17593 7.17579C6.94162 7.4101 6.94162 7.79 7.17593 8.02431L11.1342 11.9826L7.17593 15.9409C6.94162 16.1753 6.94162 16.5552 7.17593 16.7895C7.41024 17.0238 7.79014 17.0238 8.02446 16.7895L11.9825 12.8315L15.9406 16.7896C16.1749 17.0239 16.5548 17.0239 16.7891 16.7896C17.0234 16.5553 17.0234 16.1754 16.7891 15.9411L12.8308 11.9828L16.7891 8.02444Z"
              fill="#A8ABAD"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}

export default CreateMarket;
